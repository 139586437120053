import { createGlobalStyle } from 'styled-components';

import { mq } from '@/styles/media.styled';
import { styledUtilityCss } from '@/styles/utility.styled';

export const GlobalStyle = createGlobalStyle`
  /* Reset start */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  
  * {
    font: inherit;
    box-sizing: border-box;
  }

  html,
  body {
      scrollbar-gutter: stable;
  }
  
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  li {
    list-style: none;
  }

  ol, ul {
    list-style: none;
  }


  blockquote, q {
      quotes: none;
      
      &::before,
      &::after {
          content: none;
      }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* Reset end */

  html,
  body {
    font-family: Roboto, sans-serif;
    background-color: ${({ theme }) => theme.color.neutral200};
    color: ${({ theme }) => theme.color.dark};
    min-height: 100vh;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .overlay-tooltip {
    .ant-tooltip-inner {
      min-width: 30px;
      min-height: 32px;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      color: #fff;
      text-align: left;
      text-decoration: none;
      word-wrap: break-word;
      background-color: rgb(0 0 0 / 75%);
      border-radius: 12px;
      box-shadow: 0 16px 16px rgb(0 0 0 / 12%);
    }
  }

  .text-green {
    color: ${({ theme }) => theme.color.primary} !important;
  }

  .w-full {
    width: 100% !important;
  }
  ${styledUtilityCss}
  
  .text-dynamic-content {
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 64px;
      ${mq.lt.md} {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 32px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .big {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 64px;
        ${mq.lt.md} {
          font-size: 16px;
          line-height: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
      
    }

    h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 24px;

      ${mq.lt.md} {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 32px;

      }
    }

    h3 {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 24px;

      ${mq.lt.md} {
          font-size: 14px;
          line-height: 17px;
        }
    }
  }
`;
